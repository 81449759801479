import moment from "moment";
import qs from "qs";
export const appName = "Hosaid";

// LOCAL HOST
//export const baseUrl = 'http://localhost/hospital/newlife/admin/api/';

// New Life Hospital
//export const baseUrl = "http://172.17.23.7/~jrnlsco/hosaid/api/";
export const baseUrl = "https://lab.hosaid.com/api/";

// Jeyarathna Hospital
//export const baseUrl = 'https://jeyarathnahospital.hosaid.com/api/';
export const SITE_FILE_DOWNLOAD_DIR = "files_list/";
export const CardFixedTop = ({ title, children }) => {
  return (
    <div className="card card-fixed-top">
      <div className="card-body">
        <div className="d-flex  justify-content-between">
          <div style={{ padding: "5px 0 0 8px" }}>
            <b>{title}</b>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const printDocument = (printId) => {
  var content = document.getElementById(printId);
  var pri = document.getElementById("print_frame").contentWindow;
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
};

/**
 * setLs
 * to store the user inputs into localStorage
 **/
export function setLs(key, value) {
  localStorage.setItem(key, value);
}

/**
 * getLs
 * to get the stored values from localStorage
 **/
export function getLs(key) {
  return localStorage.getItem(key) || false;
}

export function getAscSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * getDescSortOrder
 * to sort an array by particular field
 * @param Property of an array
 * @return int
 */
export function getDescSortOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * removeBothSlash
 * to remove the first and last slash from a string
 * @param1 $string
 * @return String
 */
export function removeBothSlash(str) {
  return str.replace(/^\/|\/$/g, "");
}

/**
 * capitalizeFirst
 * to capitalize the first letter of the word
 * @param1 $str (string)
 * @return string
 */
export function capitalizeFirst(str) {
  if (str == "" || str == null || str == " ") {
    return "";
  }

  str = str.toLowerCase();
  //return str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

/**
 * upperCase
 * to conver the string to upper case
 * @param1 $str (string)
 * @return string
 */
export function upperCase(str) {
  if (str == "" || str == null || str == " " || str.length < 1) return "";
  return str.toString().toUpperCase();
}

/**
 * lowerCase
 * to conver the string to lower case
 * @param1 $str (string)
 * @return string
 */
export function lowerCase(str) {
  if (str == "" || str == null || str == " ") return "";
  return str.toString().toLowerCase();
}

/**
 * makeUrl
 * to convert the string into url
 * to remove all the special characters and remove space
 *
 * @param1 $str
 * @return String
 */
export function makeUrl(str) {
  try {
    str = str.replace(/[&\/\\#,+()$~%.\'":*?<>{}]/g, "");
    str = str.replace(/ /g, "-");
    return str.toLowerCase();
  } catch (error) {
    console.log(error);
  }
}

export function groupByMultiple(array, f) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

/**
 * yesorNo
 * to get the value yes or no form the boolean
 * @param1 $val (bool) true,false, 1,0
 * @param2 $withColor (true,false) default false
 * @return String
 */
export function yesorNo(val) {
  if (val == "" || val == null || val == " ") return "No";
  if (val == "1" || val == true) return "Yes";
  return "No";
}

/**
 * isNullOrEmpty
 * to check the given value is null or empty
 * @param $val
 * @return Boolean
 */
export function isNullOrEmpty(val) {
  try {
    if (val == "" || val == null || val == " " || val == NaN) return false;
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
}

/**
 * calculateMessageCount
 * to calculate letters count
 * @param1 $textArea
 * @param2 $displayArea
 * @return HTML
 */
export function calculateMsgCount(textAreaId, divId) {
  try {
    const singleSmsLength = 160;
    const ele = document.getElementById(textAreaId);
    const charLength = ele.value.length;
    const msgCount = Math.ceil(charLength / singleSmsLength);
    document.getElementById(divId).innerHTML = `${charLength} / ${msgCount}`;
  } catch (error) {
    console.log(error);
  }
}

/**
 * momentDate
 * to convert a date format using moment
 * @param1 $date
 * @param2 $format
 * @return date
 */
export function momentDate(value, toFormat = "DD/MMM/YYYY") {
  try {
    if (
      value == "0000-00-00" ||
      value == "" ||
      value == " " ||
      value == null ||
      value == NaN
    ) {
      return "";
    }

    // var d = new Date(value).getTime();
    return moment(value).format(toFormat);
  } catch (error) {
    console.log(error);
    return "";
  }
}

/**
 * integerKeyPress
 * to handle textbox key press event and check whether the input is integer or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerKeyPress(e) {
  if (e.which != 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * decimalKeyPress
 * to handle textbox key press event and check whether the input is decimal or not
 *
 * @param EVENT
 * @return NULL
 */
export function decimalKeyPress(e) {
  if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Decimal numbers only");
    e.preventDefault();
    return false;
  }
  if (e.which == 46 && e.target.value.indexOf(".") != -1) {
    // showAdvice(this, "Only one period allowed in decimal numbers");
    e.preventDefault();
    return false; // only one decimal allowed
  }
}

/**
 * lettersOnly
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function lettersOnly(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * nameWithDotAndHypen
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function nameWithDotAndHypen(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    e.which != 45 &&
    e.which != 46 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * integerIndMobile
 * to handle textbox key press event and check whether the input is integer and less than 10 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndMobile(e) {
  const len = e.target.value.length;
  if (len >= 10) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerAadhar(e) {
  const len = e.target.value.length;
  if (len >= 12) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerGst(e) {
  const len = e.target.value.length;
  if (len >= 15) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function removeExtension(filename) {
  if (filename && filename != "" && filename != null && filename.length > 3)
    return filename.split(".").slice(0, -1).join(".");
}

export function calculateAge(dob) {
  try {
    var a = moment();
    var b = moment(moment(dob), "YYYY");
    var diff = a.diff(b, "years");
    return diff;
  } catch (er) {}
}

export function replaceBulk(str, findArray, replaceArray) {
  var i,
    regex = [],
    map = {};
  for (i = 0; i < findArray.length; i++) {
    regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
    map[findArray[i]] = replaceArray[i];
  }
  regex = regex.join("|");
  str = str.replace(new RegExp(regex, "g"), function (matched) {
    return map[matched];
  });
  return str;
}

/**
 * numberToWords
 * to convert number to words
 *
 * @param1 number (int)
 * @return string
 */
export function numberToWords(amount) {
  if (!amount) return "";
  const words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  const atemp = amount.split(".");
  const number = atemp[0].split(",").join("");
  const n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
    const n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    const received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += `${words[value]} `;
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

export function testResult(
  condition,
  fromRange,
  toRange,
  result,
  gender = false
) {
  if (!condition) return true;

  try {
    var from = fromRange; //(gender=='F') ? test.female_range_from : test.range_from;
    var to = toRange; //(gender=='F') ? test.female_range_to : test.range_to;
    var cond = condition; //(gender=='F') ? test.female_reference_condition : test.reference_condition;

    if (cond == "Nothing" || cond == "Nill") {
      return true;
    }

    if (cond == "Equal") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Contain") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Less_Than") {
      return parseFloat(result) < parseFloat(from) ||
        parseFloat(result) < parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Greater_Than") {
      return parseFloat(result) > parseFloat(from) ||
        parseFloat(result) > parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Between") {
      return parseFloat(result) >= parseFloat(from) &&
        parseFloat(result) <= parseFloat(to)
        ? true
        : false;
    }
  } catch (er) {}
}

export function getDrCrColor(amt) {
  amt = parseFloat(amt);
  if (amt == 0) return "text-dark";
  else if (amt > 0) return "text-dark-success";
  else if (amt < 0) return "text-dark-danger";
}

export function typeName(str) {
  if (str && str != "" && str != null && str != NaN) {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    //return str.charAt(0).toUpperCase() + str.slice(1);
    return str.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }
  return str;
}

export function startDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format(toFormat);
}

export function endDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return moment(lastDay).format(toFormat);
}

export function emptyRowsToAdd() {
  let height = window.outerHeight;
  return Math.round((window.outerHeight - 250) / 20).toFixed(0);
}

export function queryParam(search) {
  let rv = [];
  if (search.length < 1) {
    return rv;
  }
  let str = search; //props.location.search;
  str = str.replace(/\?/g, "");
  let ar = str.split("&");
  if (ar && ar.length > 0) {
    ar.map((item, i) => {
      let m = item.split("=");
      rv[m[0]] = m[1];
    });
  }
  return rv;
}
export function getDaysBetweenTwoDates(start, end) {
  try {
    /*var start = moment("2016-09-01"), // Sept. 1st
    end = moment("2016-11-02"), // Nov. 2nd*/
    start = moment(start);
    end = moment(end);
    var day = 0; // Sunday
    var result = [];
    var current = start.clone();

    while (current.day(7 + day).isBefore(end)) {
      result.push(current.clone());
    }
    return result;
  } catch (error) {}
}

export const getFileLiveUrl = (item, originalImage = false) => {
  let url = baseUrl + SITE_FILE_DOWNLOAD_DIR;
  if (item && item.Key) url += item.Key;
  else url += item;
  let ext = getFileExtension(url);
  ext = lowerCase(ext);
  if (["png", "jpg", "jpeg", "bmp", "gif"].includes(ext))
    if (!originalImage) url = url.replace("fm", "fm_thumb");
  return url;
};

export function jsonToQuery(params) {
  return qs.stringify(params);
}

export function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function findCommonElement(array1, array2) {
  // Loop for array1
  for (let i = 0; i < array1.length; i++) {
    // Loop for array2
    for (let j = 0; j < array2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (upperCase(array1[i]) === upperCase(array2[j])) {
        // Return if common element found
        return true;
      }
    }
  }

  // Return if no common element exist
  return false;
}
export function getFileExtension(item) {
  let paths = removeBothSlash(item).split("/");
  let extension = item.substring(item.lastIndexOf("."));
  //let extension = item.split(".").slice(0, -1).join(".");

  return extension.replace(".", "");

  //return fileType;
}
